// Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
// @import 'variables';

// Bootstrap
// @import '~bootstrap/scss/bootstrap';
.grecaptcha-badge {
  visibility: hidden;
}

.rotateimg15 {
  -webkit-transform: rotate(15deg);
  -moz-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  -o-transform: rotate(15deg);
  transform: rotate(15deg);
  z-index: 15;
}

.rotateimg15n {
  -webkit-transform: rotate(-15deg);
  -moz-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  transform: rotate(-15deg);
  z-index: 5;
}

.rotateimg12-5 {
  -webkit-transform: rotate(12.5deg);
  -moz-transform: rotate(12.5deg);
  -ms-transform: rotate(12.5deg);
  -o-transform: rotate(12.5deg);
  transform: rotate(12.5deg);
  z-index: 12;
}

.rotateimg12-5n {
  -webkit-transform: rotate(-12.5deg);
  -moz-transform: rotate(-12.5deg);
  -ms-transform: rotate(-12.5deg);
  -o-transform: rotate(-12.5deg);
  transform: rotate(-12.5deg);
  z-index: 8;
}

.rotateimg10 {
  -webkit-transform: rotate(10deg);
  -moz-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  -o-transform: rotate(10deg);
  transform: rotate(10deg);
  z-index: 9;
}

.rotateimg10n {
  -webkit-transform: rotate(-10deg);
  -moz-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  -o-transform: rotate(-10deg);
  transform: rotate(-10deg);
  z-index: 11;
}

.rotateimg7-5 {
  -webkit-transform: rotate(7.5deg);
  -moz-transform: rotate(7.5deg);
  -ms-transform: rotate(7.5deg);
  -o-transform: rotate(7.5deg);
  transform: rotate(7.5deg);
  z-index: 6;
}

.rotateimg7-5n {
  -webkit-transform: rotate(-7.5deg);
  -moz-transform: rotate(-7.5deg);
  -ms-transform: rotate(-7.5deg);
  -o-transform: rotate(-7.5deg);
  transform: rotate(-7.5deg);
  z-index: 14;
}

.rotateimg5 {
  -webkit-transform: rotate(5deg);
  -moz-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  -o-transform: rotate(5deg);
  transform: rotate(5deg);
  z-index: 3;
}

.rotateimg5n {
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
  z-index: 17;
}